




























































































































































































































































































































































































































































import { create } from 'domain';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getCurrency, getSymbol } from '../../services/infos';
import flatpickr from "flatpickr";

@Component
export default class TradeForm extends Vue {
    @Prop({ default: "{}" }) tradeData: string;
    @Prop({ default: [] }) symbols: string;
    @Prop({ default: false }) loading: boolean;
    @Prop({ default: [] }) errors: string[];
    @Prop({ default: "MM/dd/yyyy" }) dateformat: string;


    mounted() {

            flatpickr("#close-time", {
                enableTime: true,
                maxDate: new Date(Date.now()),
                dateFormat: "Y-m-d\\TH:i",
                // defaultDate: new Date(),
                altInput: true,
                altFormat: this.dateformat === "MM/dd/yyyy" ? "m/d/Y, H:i" : "d/m/Y, H:i"
            });
            flatpickr("#open-time", {
                enableTime: true,
                maxDate: new Date(Date.now()),
                dateFormat: "Y-m-d\\TH:i",
                altInput: true,
                // defaultDate: new Date(),
                altFormat: this.dateformat === "MM/dd/yyyy" ? "m/d/Y, H:i" : "d/m/Y, H:i"
            });
            (document.getElementById(
                        "open-time"
                    ) as HTMLInputElement).addEventListener("change", function() {
                        const closeTimeField: any = document.getElementById("close-time");
                        const closeTime: any = closeTimeField._flatpickr.selectedDates[0];
                        if (closeTime && closeTime <= new Date(this.value)) {
                            closeTimeField._flatpickr.clear();
                        }
                        closeTimeField._flatpickr.set("minDate", this.value);
                    });

            if (JSON.parse(this.tradeData).symbol === "deposit") {
                document.getElementById('tradeForm').style.display = 'none';
                document.getElementById('tradeFormDeposit').style.display = 'block';
                document.getElementById('tradeFormWithdrawal').style.display = 'none';

            }
            if (this.tradeData !== "{}" && JSON.parse(this.tradeData).symbol === "withdrawal") {
                document.getElementById('tradeFormWithdrawal').style.display = 'block';
                document.getElementById('tradeForm').style.display = 'none';
                document.getElementById('tradeFormDeposit').style.display = 'none';
        }

    }

    setDateValue(time: number): string {
        var date = new Date(time);
        let i = date.toISOString()
        return i.substring(0,i.length-8)
    }

    cancel(e: Event) {
        e.preventDefault();
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
        document.getElementById('updateModalWrapper').style.display = 'none';
        document.body.style.overflow = 'auto';
    }
    displayTrade(currentKey:string) {

        if(currentKey==='trade'){
            document.getElementById('tradeForm').style.display = 'block';
            document.getElementById('tradeFormDeposit').style.display = 'none';
            document.getElementById('tradeFormWithdrawal').style.display = 'none';
            document.getElementById('trade').style.backgroundColor = '#E8ECFC';
            document.getElementById('trade').style.color = '#4C6EF5';
            document.getElementById('trade').style.fontWeight = '500';


            document.getElementById('deposit').style.background = '#FFFFFF';
            document.getElementById('deposit').style.color = 'black';
            document.getElementById('deposit').style.fontWeight = '400';

            document.getElementById('withdrawal').style.background = '#FFFFFF';
            document.getElementById('withdrawal').style.color = 'black';
            document.getElementById('withdrawal').style.fontWeight = '400';

        }
        if(currentKey==='deposit'){
            document.getElementById('tradeForm').style.display = 'none';
            document.getElementById('tradeFormDeposit').style.display = 'block';
            document.getElementById('tradeFormWithdrawal').style.display = 'none';

            document.getElementById('deposit').style.backgroundColor = '#E8ECFC';
            document.getElementById('deposit').style.color = '#4C6EF5';
            document.getElementById('deposit').style.fontWeight = '500';


            document.getElementById('trade').style.background = '#FFFFFF';
            document.getElementById('trade').style.color = 'black';
            document.getElementById('trade').style.fontWeight = '400';

            document.getElementById('withdrawal').style.background = '#FFFFFF';
            document.getElementById('withdrawal').style.color = 'black';
            document.getElementById('withdrawal').style.fontWeight = '400';
        }
        if(currentKey==='withdrawal'){
            document.getElementById('tradeFormWithdrawal').style.display = 'block';
            document.getElementById('tradeForm').style.display = 'none';
            document.getElementById('tradeFormDeposit').style.display = 'none';

            document.getElementById('withdrawal').style.backgroundColor = '#E8ECFC';
            document.getElementById('withdrawal').style.color = '#4C6EF5';
            document.getElementById('withdrawal').style.fontWeight = '500';

            document.getElementById('trade').style.background = '#FFFFFF';
            document.getElementById('trade').style.color = 'black';
            document.getElementById('trade').style.fontWeight = '400';

            document.getElementById('deposit').style.background = '#FFFFFF';
            document.getElementById('deposit').style.color = 'black';
            document.getElementById('deposit').style.fontWeight = '400';

        }

    }

    getInitTime(): { start: string, end: string } {
        let start = new Date().toISOString()
        start = start.substring(0, start.length-8)

        let end = new Date().toISOString()
        end = end.substring(0, end.length-8)

        return { start, end }
    }

    updateClose(e: any) {
            this.$data.maxOpen = e.target.value;
        }

    updateOpen(e: any) {
        this.$data.minClose = e.target.value;
    }

    updateOpen1(e: HTMLInputElement) {

        }
    data() {
        let currency = getCurrency();
        let currencySymbol = getSymbol();
        if (this.tradeData !== "{}") {

            let tradeData = JSON.parse(this.tradeData);
            var closeTimestampUtc = this.setDateValue(tradeData.closeTimestampUtc);
            var openTimestampUtc = this.setDateValue(tradeData.openTimestampUtc);
            return {
                ...tradeData,
                directionType: tradeData.directionType.toString(),
                openTimestampUtc,
                closeTimestampUtc,
                maxOpen: tradeData.closeTimestampUtc,
                minClose: tradeData.openTimestampUtc,
                currency,
                profit1: tradeData.profit,
                profit2: tradeData.profit,
                position:tradeData.position===null ? true : tradeData.position,
                currencySymbol,
                commission:tradeData.commission===null ? 0:tradeData.commission,
            };
        } else {
            let { start, end } = this.getInitTime();

            return {
                openTimestampUtc: start,
                closeTimestampUtc: end,
                openPrice: 0,
                closePrice: 0,
                profit: 0,
                profit1: 1,
                profit2: -1,
                volume: 1,
                symbol: "",
                position:true,
                directionType: 1,
                maxOpen: end,
                commission:0,
                minClose: start,
                currency,
                currencySymbol,

            };
        }
    }


    sendForm(e: Event) {
        e.preventDefault();

        this.$emit("send", {
            ...this.$data,
        });

        return false;
    }

    sendFormDeposit(e: Event) {
        e.preventDefault();

        this.$emit("sendDeposit", {
            ...this.$data,
        });

        return false;
    }
    sendFormWithdrawal(e: Event) {
        e.preventDefault();

        this.$emit("sendWithdrawal", {
            ...this.$data,
        });

        return false;
    }
}
