














































import { recoverPassword } from '../../services/recover-password';
import { swallMessage } from '../../services/alert';

export default {
    data() {
        return {
            formEnabled: true,
            email: "",
            error: "",

            sendFormClasses: {
                btn: true,
                'd-block': true,
                'ma-auto': true,
            },
        };
    },

    methods: {
        async askForRecover(e: Event) {
            e.preventDefault();
            if (this.formEnabled === false) return false;
            this.formEnabled = false;
            this.error = "";

            try {
                await recoverPassword(this.email);
                await swallMessage(this.$t('recovery_email_sent'), "info");

                window.location.href = this.$t("url.login");

                this.formEnabled = true;
            } catch (e) {
                this.error = e;
                this.formEnabled = true;
            }
        },
    },
};
