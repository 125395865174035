import { getCsrfHeaders } from './infos';

export async function changePassword(jwtToken: string, password: string, passwordConfirmation: string) {
    const body = new FormData();
    body.set("token", jwtToken);
    body.set("password", password);
    body.set("passwordConfirmation", passwordConfirmation);

    const { token, header } = getCsrfHeaders();
    const headers = {
        [header]: token,
    };

    const res = await fetch("/api/auth/change-password", { headers, method: "POST", body });
    const data = await res.json();

    if (data.errors) {
        throw data.errors.join("\n");
    }
}
