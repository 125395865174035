import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getStripeKey, getCsrfHeaders, getLocale } from './infos';

export async function getPrices(couponId: string = null, currency: string) {
    try {
        const query = [["coupon", couponId], ["currency", currency]].filter(e => e[1] !== null).map(e => `${e[0]}=${e[1]}`).join("&");
        const res = await fetch(`/api/stripe/prices?${query}`, { method: "GET", headers: new Headers({'content-type': 'application/json'}) });
        const data = await res.json();

        if (data.errors != null) {
            throw data.join("\n");
        }

        return data.data;
    } catch (e) {
        console.error(e);
        return null;
    }
}

let stripe: Stripe = null;
export async function getStripe() {
    if (stripe !== null) {
        return stripe;
    }

    stripe = await loadStripe(getStripeKey());
    return stripe;
}

function getClientReferenceId() {
    return window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date).getTime());
}

export async function pay(stripe: Stripe, cardNumber: {token: string}, selectedPeriod: string, locale: string, coupon: string = null) {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
    });

    if (error) {
        throw error.message;
    } else {
        const { token, header } = getCsrfHeaders();

        const headers = {
            'Content-Type': 'application/json',
            [header]: token,
        };

        const period = selectedPeriod === "monthly" ? '0' : '1';
        const res = await fetch(`/api/stripe/create-subscription/${period}/${paymentMethod.id}?devise=${getLocale()}&referral=${getClientReferenceId()}${coupon !== null ? '&coupon=' + coupon : ''}`, {method: "POST", headers});
        const data = await res.json();

        if (data.errors != null) {
            throw data.errors.join("\n");
        }

        const response = data.data;

        if (response.id === "" && response.clientSecret) {
            const result = await stripe.confirmCardPayment(response.clientSecret);

            if (result.error) {
                throw result.error.message;
            } else {
                return true;
            }
        } else if (response.id) {
            return true;
        }
    }
}


export async function newOfferpay(stripe: Stripe, cardNumber: {token: string}, selectedPeriod: string, locale: string, coupon: string = null) {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
    });

    if (error) {
        throw error.message;
    } else {
        const { token, header } = getCsrfHeaders();

        const headers = {
            'Content-Type': 'application/json',
            [header]: token,
        };

        const period = selectedPeriod === "monthly" ? '0' : '1';
        let res;
        if(period==='0'){
            res = await fetch(`/api/stripe/create-subscription-privateMonth/${period}/${paymentMethod.id}?devise=${getLocale()}&referral=${getClientReferenceId()}${coupon !== null ? '&coupon=' + coupon : ''}`, {method: "POST", headers});
        }

        if(period==='1'){
            res = await fetch(`/api/stripe/create-subscription-privateYear/${period}/${paymentMethod.id}?devise=${getLocale()}&referral=${getClientReferenceId()}${coupon !== null ? '&coupon=' + coupon : ''}`, {method: "POST", headers});
        }

        const data = await res.json();

        if (data.errors != null) {
            throw data.errors.join("\n");
        }

        const response = data.data;

        if (response.id === "" && response.clientSecret) {
            const result = await stripe.confirmCardPayment(response.clientSecret);

            if (result.error) {
                throw result.error.message;
            } else {
                return true;
            }
        } else if (response.id) {
            return true;
        }
    }
}


export async function payYear(stripe: Stripe, cardNumber: {token: string}, selectedPeriod: string, locale: string, coupon: string = null) {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
    });

    if (error) {
        throw error.message;
    } else {
        const { token, header } = getCsrfHeaders();

        const headers = {
            'Content-Type': 'application/json',
            [header]: token,
        };

        const period = selectedPeriod === "monthly" ? '0' : '1';
        const res = await fetch(`/api/stripe/create-subscription-year/${period}/${paymentMethod.id}?devise=${getLocale()}&referral=${getClientReferenceId()}${coupon !== null ? '&coupon=' + coupon : ''}`, {method: "POST", headers});
        const data = await res.json();

        if (data.errors != null) {
            throw data.errors.join("\n");
        }

        const response = data.data;

        if (response.id === "" && response.clientSecret) {
            const result = await stripe.confirmCardPayment(response.clientSecret);

            if (result.error) {
                throw result.error.message;
            } else {
                return true;
            }
        } else if (response.id) {
            return true;
        }
    }
}

export async function payMonth(stripe: Stripe, cardNumber: {token: string}, selectedPeriod: string, locale: string, coupon: string = null) {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
    });

    if (error) {
        throw error.message;
    } else {
        const { token, header } = getCsrfHeaders();

        const headers = {
            'Content-Type': 'application/json',
            [header]: token,
        };

        const period = selectedPeriod === "monthly" ? '0' : '1';
        const res = await fetch(`/api/stripe/create-subscription-newMonth/${period}/${paymentMethod.id}?devise=${getLocale()}&referral=${getClientReferenceId()}${coupon !== null ? '&coupon=' + coupon : ''}`, {method: "POST", headers});
        const data = await res.json();

        if (data.errors != null) {
            throw data.errors.join("\n");
        }

        const response = data.data;

        if (response.id === "" && response.clientSecret) {
            const result = await stripe.confirmCardPayment(response.clientSecret);

            if (result.error) {
                throw result.error.message;
            } else {
                return true;
            }
        } else if (response.id) {
            return true;
        }
    }
}
