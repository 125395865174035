import { getCaptchaSiteKey, getCaptchaAction } from './infos';
import { getLocale } from './infos';

export async function newSubmitMethod(event: Event) {
    event.preventDefault();

    const sendForm = document.getElementById("send-form") as HTMLInputElement;

    if (sendForm.disabled === true) {
        return false;
    }

    const formErrors = document.getElementById("form-errors");
    const captchaResponse = document.getElementById("g-recaptcha-response");
    const registerForm = document.getElementById('register_form') as HTMLFormElement;


    formErrors.setAttribute("style", "display: none");
    formErrors.innerHTML = "";

    sendForm.setAttribute("style", "filter: opacity(0.4)");
    sendForm.disabled = true;

    const key = getCaptchaSiteKey();
    const action = getCaptchaAction();

    const response = await grecaptcha.execute(key, { action });
    captchaResponse.setAttribute("value", response);

    const body = new FormData(registerForm);
    const lang = getLocale();

    try {
        const res = await fetch(`/api/auth/offerReg?lang=${lang}`, { method: "POST", body });
        const data = await res.json();

        if (data.errors) {
            throw data.errors.join("\n");
        }

        window.location.replace(data.data);
    } catch (e) {
        sendForm.disabled = false;
        sendForm.setAttribute("style", "");

        formErrors.setAttribute("style", "display: inherit");
        formErrors.innerHTML = e;
    }
}
