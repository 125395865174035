var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "card-like white larger",
      attrs: { method: "post" },
      on: { submit: _vm.askForChange }
    },
    [
      _vm.error !== ""
        ? _c(
            "div",
            {
              staticClass: "text-center alert error",
              attrs: { id: "form-errors" }
            },
            [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          attrs: {
            id: "password",
            required: "",
            placeholder: [_vm.$t("new_password")],
            name: "password",
            type: "password"
          },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordConfirmation,
              expression: "passwordConfirmation"
            }
          ],
          attrs: {
            id: "passwordConfirmation",
            required: "",
            placeholder: [_vm.$t("password_confirmation")],
            name: "passwordConfirmation",
            type: "password"
          },
          domProps: { value: _vm.passwordConfirmation },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.passwordConfirmation = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("input", {
        class: [_vm.sendFormClasses, { "disabled-button": !_vm.formEnabled }],
        attrs: { type: "submit", disabled: !_vm.formEnabled },
        domProps: { value: [_vm.$t("change_my_password")] }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }