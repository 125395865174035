var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row flex-center" }, [
    _vm.loading
      ? _c(
          "div",
          {
            staticClass: "loading",
            staticStyle: {
              "align-items": "center",
              display: "flex",
              "justify-content": "center"
            },
            attrs: { id: "loading" }
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "col col-6 flex-center" }, [
      _c("div", { staticClass: "price" }, [
        _c("div", { staticClass: "item" }, [
          _c(
            "form",
            {
              staticClass: "d-block important",
              staticStyle: { "margin-top": "0px" },
              attrs: { id: "payment-form" },
              on: { submit: _vm.pay }
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("div", {}, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-weight": "700", "font-size": "26px" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("pro.text")))]
                )
              ]),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { id: "price" }
                },
                [
                  _c(
                    "div",
                    { class: [_vm.coupon ? "price--strikethrough" : "price"] },
                    [
                      _vm._v(
                        "\n                              " +
                          _vm._s(
                            _vm.selectedPeriod === "yearly"
                              ? _vm.price / 12
                              : _vm.price
                          ) +
                          "\n                          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.coupon
                    ? _c("div", { staticClass: "price" }, [
                        _vm._v(
                          "\n                              " +
                            _vm._s(
                              _vm.selectedPeriod === "yearly"
                                ? _vm.reduction(_vm.yearPrice / 12)
                                : _vm.reduction(_vm.monthPrice)
                            ) +
                            "\n                          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(_vm._s(_vm.symbol) + "\n                          "),
                  _vm.coupon
                    ? _c("div", { staticClass: "card-price" }, [
                        _vm._v(
                          "\n                              -" +
                            _vm._s(_vm.coupon.reduction) +
                            "%\n                          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedPeriod === "yearly"
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component_order_per_month_yearly", [
                              _vm.reduction(_vm.price),
                              _vm.symbol
                            ])
                          )
                        )
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("component_order_per_month")))
                      ])
                ]
              ),
              _vm._v(" "),
              _c("fieldset", { staticClass: "boolean-list spacing" }, [
                _c("legend"),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "yearly",
                    type: "radio",
                    name: "test",
                    value: "yearly",
                    checked: "checked"
                  },
                  on: { change: _vm.checking }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "yearly" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "promo",
                      staticStyle: {
                        color: "white",
                        "background-color": "#0D8065"
                      }
                    },
                    [
                      _vm._v(
                        "\n                                  " +
                          _vm._s(_vm.$t("component_order_most_popular")) +
                          "\n                              "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "no-wrap" }, [
                    _vm._v(_vm._s(_vm.$t("component_order_billed_annually")))
                  ]),
                  _c("span", { staticClass: "promo no-wrap" }, [
                    _vm._v(_vm._s(_vm.$t("component_order_save")) + " 20%")
                  ])
                ]),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "monthly",
                    type: "radio",
                    name: "test",
                    value: "monthly"
                  },
                  on: { change: _vm.checking }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "no-wrap", attrs: { for: "monthly" } },
                  [_vm._v(_vm._s(_vm.$t("component_order_billed_monthly")))]
                )
              ]),
              _vm._v(" "),
              _vm.stripeError !== ""
                ? _c(
                    "div",
                    {
                      staticClass: "alert error",
                      attrs: { id: "card-errors", role: "alert" }
                    },
                    [
                      _vm._v(
                        "\n                          " +
                          _vm._s(_vm.stripeError) +
                          "\n                      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "spacing", attrs: { id: "card-element" } },
                [
                  _c("div", { staticClass: "field" }, [
                    _c("label", { staticClass: "label_input" }, [
                      _vm._v(_vm._s(_vm.$t("component_order_card_number")))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "inputWrapper centeredContent",
                      attrs: { id: "cardNumber" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "field flex-grow" }, [
                      _c("label", { staticClass: "label_input" }, [
                        _vm._v(
                          _vm._s(_vm.$t("component_order_expiration_date"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "inputWrapper centeredContent",
                        attrs: { id: "cardExpiry" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field flex-grow ml-10" }, [
                      _c("label", { staticClass: "label_input" }, [
                        _vm._v(_vm._s(_vm.$t("component_order_security_code")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "inputWrapper centeredContent",
                        attrs: { id: "cardCvc" }
                      })
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-center spacing",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c("input", {
                    staticClass: "btn",
                    attrs: { id: "checkout", name: "checkout", type: "submit" },
                    domProps: { value: [_vm.$t("checkout")] }
                  })
                ]
              ),
              _vm._v(" "),
              _c("ul", [
                _c("h4", { staticStyle: { "text-align": "start" } }, [
                  _vm._v(_vm._s(_vm.$t("get_started_pro")))
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://fixytrade.com/wp-content/uploads/2022/01/trades.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("register_upsell_up_to_302")))
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/sync.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("track_account1")))])
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "h4",
                    {
                      staticStyle: {
                        "text-align": "start",
                        color: "#2396f3",
                        "font-weight": "700",
                        margin: "0"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("advance.text")))]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("journal_trading")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("calendar.text")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("holding.text")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("analysis.text")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("advance.state.text")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("performance.text")))])
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "h4",
                    {
                      staticStyle: {
                        "text-align": "start",
                        color: "#2396f3",
                        "font-weight": "700",
                        margin: "0"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("advanceprofit.text")))]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("testing.text")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("smartgoal.text")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("invest.text")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    staticClass: "iconCheck",
                    attrs: { src: "/static/v1.3/icons/icon_check.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("projection.text")))])
                ])
              ]),
              _vm._v(" "),
              _c(
                "h5",
                {
                  staticClass: "price_aditonal",
                  staticStyle: { margin: "15px 30px", "text-align": "center" }
                },
                [_vm._v(_vm._s(_vm.$t("moneyback.text")))]
              ),
              _vm._v(" "),
              _c(
                "h5",
                {
                  staticClass: "price_aditonal",
                  staticStyle: { margin: "0px 30px", "text-align": "center" }
                },
                [_vm._v(_vm._s(_vm.$t("moneyback1.text")))]
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader" }, [
      _c("div"),
      _vm._v(" "),
      _c("div")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "margin-top": "40px",
          position: "relative",
          display: "flex",
          "justify-content": "center"
        }
      },
      [
        _c("img", {
          staticClass: "wp-image-236941",
          attrs: {
            width: "80",
            height: "auto",
            src:
              "https://fixytrade.com/wp-content/uploads/2022/04/grow-icon@2x.png",
            alt: "",
            title: "grow-icon@2x",
            srcset:
              "https://fixytrade.com/wp-content/uploads/2022/04/grow-icon@2x.png" +
              " 160w, " +
              "https://fixytrade.com/wp-content/uploads/2022/04/grow-icon@2x-150x150.png" +
              " 150w",
            sizes: "(max-width: 160px) 100vw, 160px"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }