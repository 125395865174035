import { getCsrfHeaders, getLocale } from './infos';

export async function recoverPassword(email: string) {
    const body = new FormData();
    body.set("email", email);

    const { token, header } = getCsrfHeaders();
    const headers = {
        [header]: token,
    };

    const res = await fetch(`/api/auth/recover-password?lang=${getLocale()}`, { headers, method: "POST", body });
    const data = await res.json();

    if (data.errors) {
        throw data.errors.join("\n");
    }
}
