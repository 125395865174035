import VueI18n from 'vue-i18n';
import { getLocale } from '../infos';
// import messages from './i18n.json'
import messages from './I18n.json';

export function generateI18n() {
    return new VueI18n({
        locale: getLocale(),
        messages,
    })
}
