var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "card-like white larger",
      attrs: { method: "post" },
      on: { submit: _vm.askForRecover }
    },
    [
      _vm.error !== ""
        ? _c(
            "div",
            {
              staticClass: "text-center alert error",
              attrs: { id: "form-errors" }
            },
            [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          attrs: {
            id: "email",
            required: "",
            name: "email",
            placeholder: [_vm.$t("email.address")],
            type: "email",
            autocomplete: "email"
          },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("input", {
        class: [_vm.sendFormClasses, { "disabled-button": !_vm.formEnabled }],
        attrs: { type: "submit", disabled: !_vm.formEnabled },
        domProps: { value: [_vm.$t("request_password_reset")] }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("hr"),
        _vm._v(" "),
        _c("a", { attrs: { href: [_vm.$t("url.register")] } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("login_create_an_account")) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("a", { attrs: { href: [_vm.$t("url.login")] } }, [
          _vm._v("\n            " + _vm._s(_vm.$t("login")) + "\n        ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }