var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row flex-center" }, [
    _vm.loading
      ? _c(
          "div",
          {
            staticClass: "loading",
            staticStyle: {
              "align-items": "center",
              display: "flex",
              "justify-content": "center"
            },
            attrs: { id: "loading" }
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "col col-6 flex-center" }, [
      _c("div", { staticClass: "price" }, [
        _c("div", { staticClass: "item" }, [
          _c(
            "form",
            {
              staticClass: "d-block important",
              staticStyle: { "margin-top": "0px" },
              attrs: { id: "payment-form" },
              on: { submit: _vm.pay }
            },
            [
              _c("div", { staticClass: "price_rate pro" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("component_order_pro")))])
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "30px 30px 30px" } }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "700",
                      "font-size": "26px",
                      color: "#393949"
                    }
                  },
                  [_vm._v("14.97€")]
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "6px",
                      "text-decoration": "line-through"
                    }
                  },
                  [_vm._v("24.95€")]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("goal.monthly")))])
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "promo no-wrap" }, [
                _vm._v(_vm._s(_vm.$t("component_order_save")) + " 40%")
              ]),
              _vm._v(" "),
              _vm.stripeError !== ""
                ? _c(
                    "div",
                    {
                      staticClass: "alert error",
                      attrs: { id: "card-errors", role: "alert" }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.stripeError) +
                          "\n                    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "spacing", attrs: { id: "card-element" } },
                [
                  _c("div", { staticClass: "field" }, [
                    _c("label", { staticClass: "label_input" }, [
                      _vm._v(_vm._s(_vm.$t("component_order_card_number")))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "inputWrapper centeredContent",
                      attrs: { id: "cardNumber" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "field flex-grow" }, [
                      _c("label", { staticClass: "label_input" }, [
                        _vm._v(
                          _vm._s(_vm.$t("component_order_expiration_date"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "inputWrapper centeredContent",
                        attrs: { id: "cardExpiry" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field flex-grow ml-10" }, [
                      _c("label", { staticClass: "label_input" }, [
                        _vm._v(_vm._s(_vm.$t("component_order_security_code")))
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "inputWrapper centeredContent",
                        attrs: { id: "cardCvc" }
                      })
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-center spacing",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c("input", {
                    staticClass: "btn",
                    attrs: { id: "checkout", name: "checkout", type: "submit" },
                    domProps: { value: [_vm.$t("checkout")] }
                  })
                ]
              ),
              _vm._v(" "),
              _c("ul", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("component_order_advanced_tools_to")))
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/trades.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("register_upsell_up_to_302")))
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/sync.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("track_account1")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/clock.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("hour_update1")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("goalSet")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("tagSet")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("scoreSet")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("advanceSet")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("monthSet")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("dailySet")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("hourlySet")))])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("holdSet")))])
                ])
              ]),
              _vm._v(" "),
              _c(
                "h5",
                {
                  staticClass: "price_aditonal",
                  staticStyle: { margin: "15px 30px", "text-align": "center" }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t("component_order_day_satisfaction_guarantee")
                      ) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("component_order_cancel_anytime")) +
                      "\n                    "
                  )
                ]
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader" }, [
      _c("div"),
      _vm._v(" "),
      _c("div")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }