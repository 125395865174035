import Swal, { SweetAlertResult, SweetAlertIcon } from 'sweetalert2';

window.Swal = Swal;
export function swallMessage(message: string, typeMessage: string): Promise<SweetAlertResult<void>> {
    return Swal.fire<void>({
        icon: `${typeMessage}` as SweetAlertIcon,
        text: `${message}`,
        showClass: {
            popup: 'animate__animated animate__fadeInDown animate__faster',
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp animate__faster',
        },
    });
}

