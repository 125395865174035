












import { Component, Vue, Prop } from 'vue-property-decorator';
import { getCsrfHeaders } from '../../services/infos';
import TradeForm from '../TradeForm/TradeForm.vue';
import { displayAccountUpgrade } from '../../services/accountUpgrade';

@Component({
    components: {
        "trade-form": TradeForm,
    },
})
export default class TradeFormCreate extends Vue {
    @Prop({ default: "[]" }) jsonsymbols: string;
    @Prop() brokeruuid: string;
    @Prop({ default: "MM/dd/yyyy" }) dateformat: string;
    @Prop() brokeraccountalias: string;

    async handleSend(tradeForm: object): Promise<void> {
        this.$data.loading = true;
        this.$data.errors = [];

        let { header, token } = getCsrfHeaders();
        let headers = {
            [header]: token,
        };

        let body = new FormData();
        for (let [k, v] of Object.entries(tradeForm)) {
            body.append(k, v);
        }

        body.append("userBrokerAccountUuid", this.brokeruuid);

        try {
            let res = await fetch(`/brokerAccountDetail/${this.brokeraccountalias}/${this.brokeruuid}/create`, {method: "POST", body, headers});
            let data = await res.json();

            if (res.status === 401) { // UNAUTHORIZED
               // displayAccountUpgrade();
                this.$data.loading = false;
                return;
            }

            if (data.errors != null) {
                throw data.errors;
            }

            location.reload();
        } catch (e) {

            this.$data.loading = false;

            if (e == "starter_limit_reached") {
            displayAccountUpgrade();
                this.$data.errors = [this.$t('trade_limit_reached')];
                return;
            }

            this.$data.errors = e;
        }
    }

    async handleDeposit(tradeForm: object): Promise<void> {

        this.$data.loading = true;
        this.$data.errors = [];

        let { header, token } = getCsrfHeaders();
        let headers = {
            [header]: token,
        };

        let body = new FormData();
        for (let [k, v] of Object.entries(tradeForm)) {
            body.append(k, v);
        }

        body.append("userBrokerAccountUuid", this.brokeruuid);

        try {
            let res = await fetch(`/brokerAccountDetail/${this.brokeraccountalias}/${this.brokeruuid}/createDeposit`, {method: "POST", body, headers});
            let data = await res.json();

            if (res.status === 401) { // UNAUTHORIZED
                displayAccountUpgrade();
                this.$data.loading = false;
                return;
            }

            if (data.errors != null) {
                throw data.errors;
            }

            location.reload();
        } catch (e) {
            displayAccountUpgrade();
            this.$data.loading = false;

            if (e == "starter_limit_reached") {
                this.$data.errors = [this.$t('trade_limit_reached')];
                return;
            }

            this.$data.errors = e;
        }
    }

    async handleSendWithdrawal(tradeForm: object): Promise<void> {
        this.$data.loading = true;
        this.$data.errors = [];

        let { header, token } = getCsrfHeaders();
        let headers = {
            [header]: token,
        };

        let body = new FormData();
        for (let [k, v] of Object.entries(tradeForm)) {
            body.append(k, v);
        }

        body.append("userBrokerAccountUuid", this.brokeruuid);

        try {
            let res = await fetch(`/brokerAccountDetail/${this.brokeraccountalias}/${this.brokeruuid}/createWithdrawal`, {method: "POST", body, headers});
            let data = await res.json();

            if (res.status === 401) { // UNAUTHORIZED
                displayAccountUpgrade();
                this.$data.loading = false;
                return;
            }

            if (data.errors != null) {
                throw data.errors;
            }

            location.reload();
        } catch (e) {
            displayAccountUpgrade();
            this.$data.loading = false;

            if (e == "starter_limit_reached") {
                this.$data.errors = [this.$t('trade_limit_reached')];
                return;
            }

            this.$data.errors = e;
        }
    }

    data() {
        let symbols = JSON.parse(this.jsonsymbols);
        return {
            symbols,
            errors: [] as string[],
            loading: false
        };
    }
}
