
































import { Component, Prop, Vue } from 'vue-property-decorator';
import TradeFormUpdate from "../TradeFormUpdate/TradeFormUpdate.vue";

@Component({
    components: {
        "trade-form-update": TradeFormUpdate,
    },
})
export default class UpdateTradeButton extends Vue {
    @Prop({ default: "{}" }) tradeData: string;
    @Prop() brokeraccountalias: string;

    closePopup() {
        document.getElementById('updateModalWrapper').style.display = 'none';
        document.body.style.overflow = "auto";
    }

    openPopup() {
        document.body.style.overflow = "hidden";
        document.getElementById('updateModalWrapper').style.display = 'block';
    }

    data() {
        return {
            data: this.tradeData,
            broker: this.brokeraccountalias,
            open: false,
        };
    }
}
