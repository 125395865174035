var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: [
          _vm.tradeData === "{}"
            ? {
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px"
              }
            : { display: "none" }
        ]
      },
      [
        _c(
          "button",
          {
            staticClass: "tab active-tab",
            attrs: { id: "trade" },
            on: {
              click: function($event) {
                return _vm.displayTrade("trade")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("btn.trade")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "tab",
            attrs: { id: "deposit" },
            on: {
              click: function($event) {
                return _vm.displayTrade("deposit")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("btn.deposit")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "tab",
            attrs: { id: "withdrawal" },
            on: {
              click: function($event) {
                return _vm.displayTrade("withdrawal")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("btn.withdrawal")))]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticStyle: { display: "none" },
        style: [
          JSON.parse(_vm.tradeData).symbol === "deposit" ||
          JSON.parse(_vm.tradeData).symbol === "withdrawal"
            ? { display: "none" }
            : { display: "block" }
        ],
        attrs: { id: "tradeForm" },
        on: { submit: _vm.sendForm }
      },
      [
        _c("h3", { staticClass: "addTradeFormHeader" }, [
          _vm._v(_vm._s(_vm.$t("general_data")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: { margin: "0", padding: "0", "flex-wrap": "wrap" }
          },
          [
            _c("div", { staticClass: "field spaced-field" }, [
              _c(
                "label",
                {
                  staticClass: "label_input",
                  attrs: { id: "symbol_input", for: "symbol" }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "portfolios_2_broker_trades_trade_details_symbol"
                        )
                      ) +
                      "\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.symbol,
                    expression: "symbol"
                  }
                ],
                staticClass: "input tradeFormInput",
                attrs: {
                  id: "symbol",
                  name: "symbol",
                  type: "text",
                  list: "symbol_list",
                  required: ""
                },
                domProps: { value: _vm.symbol },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.symbol = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "datalist",
                { attrs: { id: "symbol_list" } },
                _vm._l(_vm.symbols, function(symbol) {
                  return _c(
                    "option",
                    { key: symbol, domProps: { value: symbol } },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(symbol) +
                          "\n                        "
                      )
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "fieldset",
              {
                staticClass: "boolean-list grow-direction-type",
                staticStyle: { "margin-right": "30px" }
              },
              [
                _c(
                  "legend",
                  {
                    staticClass: "account_legend",
                    attrs: { id: "directionType_account_type" }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "portfolios_2_broker_trades_trade_details_side"
                          )
                        ) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.directionType,
                      expression: "directionType"
                    }
                  ],
                  attrs: { id: "directionType0", type: "radio", value: "1" },
                  domProps: {
                    checked: _vm.directionType === 1,
                    checked: _vm._q(_vm.directionType, "1")
                  },
                  on: {
                    change: function($event) {
                      _vm.directionType = "1"
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "label_input radio_input",
                    attrs: { for: "directionType0" },
                    on: {
                      click: function($event) {
                        _vm.directionType = 1
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("portfolios_2_broker_trades_trade_details_buy")
                        ) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.directionType,
                      expression: "directionType"
                    }
                  ],
                  attrs: { id: "directionType1", type: "radio", value: "-1" },
                  domProps: {
                    checked: _vm.directionType === -1,
                    checked: _vm._q(_vm.directionType, "-1")
                  },
                  on: {
                    change: function($event) {
                      _vm.directionType = "-1"
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "label_input radio_input",
                    attrs: { for: "directionType1" },
                    on: {
                      click: function($event) {
                        _vm.directionType = -1
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("cap_sell")) +
                        "\n                    "
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "addTradeFormHeader" }, [
          _vm._v(_vm._s(_vm.$t("trade_entry")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: { margin: "0", padding: "0", "flex-wrap": "wrap" }
          },
          [
            _c("div", { staticClass: "field spaced-field" }, [
              _c(
                "label",
                {
                  ref: "openTimestampUtc_input",
                  staticClass: "label_input",
                  attrs: {
                    id: "openTimestampUtc_input",
                    for: "openTimestampUtc"
                  }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t(
                          "portfolios_2_broker_trades_trade_details_open_date"
                        )
                      ) +
                      "\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.openTimestampUtc,
                    expression: "openTimestampUtc"
                  }
                ],
                ref: "openTime",
                staticClass: "tradeFormInput",
                attrs: {
                  required: "",
                  type: "text",
                  id: "open-time",
                  name: "open-time",
                  placeholder: _vm.$t(
                    "portfolios_2_broker_trades_trade_details_open_date"
                  )
                },
                domProps: { value: _vm.openTimestampUtc },
                on: {
                  change: _vm.updateOpen,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.openTimestampUtc = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field spaced-field" }, [
              _c(
                "label",
                {
                  staticClass: "label_input",
                  attrs: { id: "openPrice_input", for: "openPrice" }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t("portfolios_2_broker_trades_trade_details_entry")
                      ) +
                      "\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.openPrice,
                    expression: "openPrice"
                  }
                ],
                staticClass: "number_input input tradeFormInput",
                attrs: {
                  id: "openPrice",
                  step: "0.00001",
                  type: "number",
                  name: "openPrice",
                  placeholder: _vm.$t(
                    "portfolios_2_broker_trades_trade_details_entry"
                  ),
                  required: ""
                },
                domProps: { value: _vm.openPrice },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.openPrice = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field spaced-field" }, [
              _c(
                "label",
                {
                  staticClass: "label_input",
                  attrs: { id: "volume_input", for: "volume" }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("portfolios_2_broker_trades_volume")) +
                      "\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.volume,
                    expression: "volume"
                  }
                ],
                staticClass: "number_input_default input tradeFormInput",
                attrs: {
                  id: "volume",
                  type: "number",
                  step: "0.01",
                  name: "volume",
                  placeholder: _vm.$t("portfolios_2_broker_trades_volume"),
                  value: "1"
                },
                domProps: { value: _vm.volume },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.volume = $event.target.value
                  }
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "addTradeFormHeader" }, [
          _vm._v(_vm._s(_vm.$t("trade_exit")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: { margin: "0", padding: "0", "flex-wrap": "wrap" }
          },
          [
            _c(
              "fieldset",
              {
                staticClass: "boolean-list",
                staticStyle: {
                  width: "46%",
                  "margin-right": "10px",
                  "margin-top": "15px"
                }
              },
              [
                _c(
                  "legend",
                  {
                    staticClass: "account_legend",
                    attrs: { id: "closeTradeId" }
                  },
                  [
                    _vm._v(
                      "\n                                                " +
                        _vm._s(_vm.$t("closed_trade")) +
                        "\n                                            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.position,
                      expression: "position"
                    }
                  ],
                  attrs: { id: "closeTradeId0", type: "radio", value: "true" },
                  domProps: {
                    checked: _vm.position === true,
                    checked: _vm._q(_vm.position, "true")
                  },
                  on: {
                    change: function($event) {
                      _vm.position = "true"
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "label_input radio_input",
                    attrs: { for: "closeTradeId0" },
                    on: {
                      click: function($event) {
                        _vm.position = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                                                " +
                        _vm._s(_vm.$t("closed_yes")) +
                        "\n                                            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.position,
                      expression: "position"
                    }
                  ],
                  attrs: { id: "closeTradeId1", type: "radio", value: "false" },
                  domProps: {
                    checked: _vm.position === false,
                    checked: _vm._q(_vm.position, "false")
                  },
                  on: {
                    change: function($event) {
                      _vm.position = "false"
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "label_input radio_input",
                    attrs: { for: "closeTradeId1" },
                    on: {
                      click: function($event) {
                        _vm.position = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                                                " +
                        _vm._s(_vm.$t("closed_no")) +
                        "\n                                            "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "field spaced-field fieldStyles" }, [
              _c(
                "label",
                {
                  staticClass: "label_input",
                  attrs: { id: "profit_input", for: "profit" }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.position
                          ? _vm.$t("portfolios_5_broker_goals_profit_5")
                          : _vm.$t("unrealized_profit_label")
                      ) +
                      " (" +
                      _vm._s(_vm.currencySymbol) +
                      ")\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.profit,
                    expression: "profit"
                  }
                ],
                staticClass: "number_input input tradeFormInput",
                attrs: {
                  id: "profit",
                  type: "number",
                  name: "profit",
                  placeholder: _vm.$t("portfolios_5_broker_goals_profit_5"),
                  step: "0.01",
                  required: ""
                },
                domProps: { value: _vm.profit },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.profit = $event.target.value
                  }
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "tradeexit" } }, [
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: { margin: "0", padding: "0", "flex-wrap": "wrap" }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.position === true,
                      expression: "position === true"
                    }
                  ],
                  staticClass: "field spaced-field"
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "label_input",
                      attrs: {
                        id: "closeTimestampUtc_input",
                        for: "closeTimestampUtc"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("component_trade_list_close_date")) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.closeTimestampUtc,
                        expression: "closeTimestampUtc"
                      }
                    ],
                    ref: "closeTime",
                    staticClass: "tradeFormInput",
                    attrs: {
                      required: "",
                      type: "text",
                      id: "close-time",
                      name: "close-time",
                      placeholder: _vm.$t("component_trade_list_close_date")
                    },
                    domProps: { value: _vm.closeTimestampUtc },
                    on: {
                      change: _vm.updateClose,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.closeTimestampUtc = $event.target.value
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "field spaced-field" }, [
                _c(
                  "label",
                  {
                    staticClass: "label_input",
                    attrs: { id: "closePrice_input", for: "closePrice" }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.position
                            ? _vm.$t(
                                "portfolios_2_broker_trades_trade_details_exit"
                              )
                            : _vm.$t("current_price_label")
                        ) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.closePrice,
                      expression: "closePrice"
                    }
                  ],
                  staticClass: "number_input input tradeFormInput",
                  attrs: {
                    id: "closePrice",
                    type: "number",
                    name: "closePrice",
                    placeholder: _vm.$t(
                      "portfolios_2_broker_trades_trade_details_exit"
                    ),
                    step: "0.00001",
                    required: ""
                  },
                  domProps: { value: _vm.closePrice },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.closePrice = $event.target.value
                    }
                  }
                })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "tradeexit1" } }, [
          _vm.position === true
            ? _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: {
                    padding: "0px",
                    "margin-top": "0px",
                    "flex-wrap": "wrap"
                  }
                },
                [
                  _c("div", { staticClass: "field spaced-field" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label_input",
                        attrs: { id: "cost_input", for: "commission" }
                      },
                      [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.$t("cost_text")) +
                            " (" +
                            _vm._s(_vm.currencySymbol) +
                            ")\n                                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.commission,
                          expression: "commission"
                        }
                      ],
                      staticClass: "number_input input tradeFormInput",
                      attrs: {
                        id: "commission",
                        type: "number",
                        name: "commission",
                        min: "0",
                        placeholder: _vm.$t("cost_text"),
                        step: "0.01",
                        required: ""
                      },
                      domProps: { value: _vm.commission },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.commission = $event.target.value
                        }
                      }
                    })
                  ])
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row addTradefooterWrapper" }, [
          _c(
            "button",
            {
              staticClass: "default_add_btn addTradeCancelWrapper",
              attrs: { id: "cancelButton" },
              on: { click: _vm.cancel }
            },
            [
              _c(
                "p",
                { staticStyle: { color: "#4C6EF5", "padding-left": "0px" } },
                [_vm._v(_vm._s(_vm.$t("billing_cancel")))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "default_add_btn",
              staticStyle: {
                padding: "20px 50px",
                width: "auto",
                "margin-top": "0px"
              },
              attrs: {
                id: "submitButton",
                type: "submit",
                disabled: _vm.loading === true
              }
            },
            [
              _c("p", { staticStyle: { "padding-left": "0px" } }, [
                _vm._v(_vm._s(_vm.$t("confirm_trade")))
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm.errors !== ""
          ? _c(
              "div",
              {
                staticClass: "col flex-grow",
                staticStyle: { color: "red", "font-weight": "bold" },
                attrs: { id: "form-errors" }
              },
              _vm._l(_vm.errors, function(error, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    staticStyle: {
                      "white-space": "pre-line",
                      color: "red",
                      "font-weight": "bold"
                    }
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(error) + "\n            "
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticStyle: { display: "none" },
        style: [
          JSON.parse(_vm.tradeData).symbol === "deposit"
            ? { display: "block" }
            : { display: "none" }
        ],
        attrs: { id: "tradeFormDeposit" },
        on: { submit: _vm.sendFormDeposit }
      },
      [
        _c("h3", { staticClass: "addTradeFormHeader" }, [
          _vm._v(_vm._s(_vm.$t("general_data")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: { margin: "0", padding: "0", "flex-wrap": "wrap" }
          },
          [
            _c(
              "div",
              {
                staticClass: "field spaced-field",
                staticStyle: { width: "auto" }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "label_input",
                    attrs: {
                      id: "openPrice_input_deposit",
                      for: "profitDeposit"
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("deposit.placeholder")) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.profit1,
                      expression: "profit1"
                    }
                  ],
                  staticClass: "number_input input tradeFormInput",
                  attrs: {
                    id: "profitDeposit",
                    type: "number",
                    name: "profit",
                    min: 1,
                    required: "",
                    placeholder: _vm.$t("deposit.placeholder")
                  },
                  domProps: { value: _vm.profit1 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.profit1 = $event.target.value
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "field spaced-field",
                staticStyle: { width: "auto", "margin-right": "10px" }
              },
              [
                _c(
                  "label",
                  {
                    ref: "openTimestampUtc_input",
                    staticClass: "label_input",
                    attrs: {
                      id: "openTimestampUtc_input1",
                      for: "openTimestampUtc"
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "portfolios_2_broker_trades_trade_details_open_date"
                          )
                        ) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.openTimestampUtc,
                      expression: "openTimestampUtc"
                    }
                  ],
                  ref: "openTime",
                  staticClass: "tradeFormInput",
                  attrs: {
                    required: "",
                    type: "text",
                    id: "open-time",
                    name: "open-time",
                    placeholder: _vm.$t(
                      "portfolios_2_broker_trades_trade_details_open_date"
                    )
                  },
                  domProps: { value: _vm.openTimestampUtc },
                  on: {
                    change: _vm.updateOpen,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.openTimestampUtc = $event.target.value
                    }
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: {
              margin: "15px -20px -20px",
              padding: "15px",
              "justify-content": "center",
              background: "#eff4fa",
              "flex-wrap": "wrap"
            }
          },
          [
            _c(
              "button",
              {
                staticClass: "default_add_btn addTradeCancelWrapper",
                attrs: { id: "cancelButton" },
                on: { click: _vm.cancel }
              },
              [
                _c(
                  "p",
                  { staticStyle: { color: "#4C6EF5", "padding-left": "0px" } },
                  [_vm._v(_vm._s(_vm.$t("billing_cancel")))]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "default_add_btn",
                staticStyle: {
                  padding: "20px 50px",
                  width: "auto",
                  "margin-top": "0px"
                },
                attrs: {
                  id: "submitButton1",
                  type: "submit",
                  disabled: _vm.loading === true
                }
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("confirm_trade")))])]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticStyle: { display: "none" },
        style: [
          JSON.parse(_vm.tradeData).symbol === "withdrawal"
            ? { display: "block" }
            : { display: "none" }
        ],
        attrs: { id: "tradeFormWithdrawal" },
        on: { submit: _vm.sendFormWithdrawal }
      },
      [
        _c(
          "h3",
          { staticStyle: { margin: "0px 0px 2px 0px", color: "#4C6EF5" } },
          [_vm._v(_vm._s(_vm.$t("general_data")))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: { margin: "0", padding: "0", "flex-wrap": "wrap" }
          },
          [
            _c(
              "div",
              {
                staticClass: "field spaced-field",
                staticStyle: { width: "46%" }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "label_input",
                    attrs: {
                      id: "openPrice_input_deposit1",
                      for: "profitDeposit"
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("withdrawal.placeholder")) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.profit2,
                      expression: "profit2"
                    }
                  ],
                  staticClass: "number_input input tradeFormInput",
                  attrs: {
                    id: "profitDeposit1",
                    type: "number",
                    name: "profit",
                    required: "",
                    max: -1,
                    placeholder: _vm.$t("withdrawal.placeholder")
                  },
                  domProps: { value: _vm.profit2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.profit2 = $event.target.value
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "field spaced-field",
                staticStyle: { width: "46%", "margin-right": "10px" }
              },
              [
                _c(
                  "label",
                  {
                    ref: "openTimestampUtc_input",
                    staticClass: "label_input",
                    attrs: {
                      id: "openTimestampUtc_input12",
                      for: "openTimestampUtc"
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(
                            "portfolios_2_broker_trades_trade_details_open_date"
                          )
                        ) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.openTimestampUtc,
                      expression: "openTimestampUtc"
                    }
                  ],
                  ref: "openTime",
                  staticClass: "tradeFormInput",
                  attrs: {
                    required: "",
                    type: "text",
                    id: "open-time",
                    name: "open-time",
                    placeholder: _vm.$t(
                      "portfolios_2_broker_trades_trade_details_open_date"
                    )
                  },
                  domProps: { value: _vm.openTimestampUtc },
                  on: {
                    change: _vm.updateOpen,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.openTimestampUtc = $event.target.value
                    }
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: {
              margin: "15px -20px -20px",
              padding: "15px",
              "justify-content": "center",
              background: "#eff4fa",
              "flex-wrap": "wrap"
            }
          },
          [
            _c(
              "button",
              {
                staticClass: "default_add_btn addTradeCancelWrapper",
                attrs: { id: "cancelButton" },
                on: { click: _vm.cancel }
              },
              [
                _c(
                  "p",
                  { staticStyle: { color: "#4C6EF5", "padding-left": "0px" } },
                  [_vm._v(_vm._s(_vm.$t("billing_cancel")))]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "default_add_btn",
                staticStyle: {
                  padding: "20px 50px",
                  width: "auto",
                  "margin-top": "0px"
                },
                attrs: {
                  id: "submitButton12",
                  type: "submit",
                  disabled: _vm.loading === true
                }
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("confirm_trade")))])]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }