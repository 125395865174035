












import { Component, Vue, Prop } from 'vue-property-decorator';
import { getCsrfHeaders } from '../../services/infos';
import TradeForm from '../TradeForm/TradeForm.vue';
import { displayAccountUpgrade } from '../../services/accountUpgrade';

@Component({
    components: {
        "trade-form": TradeForm,
    },
})
export default class TradeFormUpdate extends Vue {
    @Prop({ default: "[]" }) jsonsymbols: string;
    @Prop({ default: "{}" }) tradeData: string;
    @Prop() brokeruuid: string;
    @Prop() brokeraccountalias: string;

    async handleSend(tradeForm: { id: string, userBrokerAccountUuid: string }): Promise<void> {
        this.$data.loading = true;
        this.$data.errors = [];

        let { header, token } = getCsrfHeaders();
        let headers = {
            [header]: token,
        };

        let body = new FormData();
        for (let [k, v] of Object.entries(tradeForm)) {
            if (v !== null && v !== undefined) {
                body.append(k, v);
            }
        }

        try {
            let res = await fetch(`/brokerAccountDetail/${this.brokeraccountalias}/${tradeForm.userBrokerAccountUuid}/update/${tradeForm.id}`, {method: "POST", body, headers});
            let data = await res.json();

            if (res.status === 401) { // UNAUTHORIZED
                displayAccountUpgrade();
                this.$data.loading = false;
                return;
            }

            if (data.errors != null) {
                throw data.errors;
            }

            location.reload();
        } catch (e) {
            console.error(e);
            this.$data.errors = e;
            this.$data.loading = false;
        }
    }

    data() {
        let symbols = JSON.parse(this.jsonsymbols);

        return {
            data: this.tradeData,
            symbols,
            errors: [] as string[],
            loading: false,
        };
    }
}
