import "regenerator-runtime/runtime.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Tagify from "@yaireo/tagify";

// Typescript types
import './types/window';

// Services
import { register } from './services/register';
import { newSubmitMethod } from './services/register1';
import { swallMessage } from './services/alert';
import { getStripe } from './services/payment';
import { generateI18n } from './services/translate';
import { getCsrfHeaders } from './services/infos';
import { displayAccountUpgrade } from "./services/accountUpgrade";
import { getBalancePerDay, getProfitOrLossPerDay } from "./services/graphics";

// Components vue
import Payment from './components/Payment';
import NewPayment from './components/NewPaymentOffer';
import PaymentOffer from './components/PaymentOffer';
import RecoverPassword from './components/RecoverPassword';
import ChangePassword from './components/ChangePassword';
import TradeFormCreate from './components/TradeFormCreate';
import UpdateTradeButton from './components/UpdateTradeButton';

// styles
import './styles/Pricing.scss';
import './styles/LoginAndRegister.scss';
import PaymentOfferMonth from "./components/PaymentOfferMonth";

// Retrocompat des scripts statiques
window.newSubmitMethod =newSubmitMethod;
window.register = register;
window.swallMessage = swallMessage;
window.getStripe = getStripe;
window.getCsrfHeaders = getCsrfHeaders;
window.Tagify = Tagify;
window.displayAccountUpgrade = displayAccountUpgrade;
window.getBalancePerDay = getBalancePerDay;
window.getProfitOrLossPerDay = getProfitOrLossPerDay;

// Ajout des plugins vue
Vue.use(VueI18n);

function initializeComponents() {
    function registerComponent(id, clazz) {
        const i18n = generateI18n();
        let el = document.getElementById(id);
        if (el !== null) {
            new Vue({ i18n, el: `#${id}`, render: h => h(clazz, { props: {...el.dataset} }) });
        }
    }

    registerComponent("update-trade-button", UpdateTradeButton);
    registerComponent("payment-element", Payment);
    registerComponent("payment-elementnew", NewPayment);
    registerComponent("payment-element1", PaymentOffer);
    registerComponent("payment-element-month", PaymentOfferMonth);
    registerComponent("trade-form-create", TradeFormCreate);
    registerComponent("recover-password", RecoverPassword);
    registerComponent("change-password", ChangePassword);
}
// Initialize components when DOM is fully loaded
window.addEventListener('DOMContentLoaded', initializeComponents);
// Listen for custom event to re-initialize components
window.addEventListener('domUpdated', initializeComponents);
