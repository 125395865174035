import { first, groupBy } from 'lodash';
import moment from 'moment';
import { getLocale } from './infos';

const sortByTimeAsc = (a: string, b: string): number => new Date(a).getTime() - new Date(b).getTime();
const formatDate = (e: string, dateFormat: any): string => moment(e).format(dateFormat === "MM/dd/yyyy" ? "MM/DD/YYYY" : "DD/MM/YYYY");
interface Trade {
    closeTimestampUtc: string,
    profit: number,
}

export function getBalancePerDay(trades: Trade[], initialBalance = 0, dateFormat: any = "DD/MM/YYYY") {

    let balance = initialBalance;

    const group = groupBy(trades, (e: Trade) => new Date(e.closeTimestampUtc.toLocaleString()).toDateString()) as { [date: string]: Trade[] };

    const tradeDays = Object.values(group).map((trades: Trade[]) => {
        const closedDate = trades[0].closeTimestampUtc;
        const profit = trades.map((trade: Trade) => trade.profit).reduce((a, b) => a + b, 0);

        balance = balance + profit;

        return {
            closedDate: formatDate(closedDate, dateFormat),
            balance: balance.toFixed(2),
        };
    });

    // Add initial balance
    const tradeDaysSorted = tradeDays.sort((a, b) => sortByTimeAsc(a.closedDate, b.closedDate));
    const firstTrade = first(trades.sort((a, b) => sortByTimeAsc(a.closeTimestampUtc, b.closeTimestampUtc))) as Trade;
    const closedDate = formatDate(firstTrade.closeTimestampUtc, dateFormat);

    const profit = trades.map((trade: Trade) => trade.profit).reduce((a, b) => a + b, 0);

    balance = balance + profit;

    if (initialBalance === 0) {
        return [{ closedDate, balance: firstTrade.profit }, ...tradeDaysSorted];
    } else {
        return [{ closedDate, balance: initialBalance }, ...tradeDaysSorted];
    }
}

export function getProfitOrLossPerDay(trades: Trade[], dateFormat: any = "DD/MM/YYYY") {

    const group = groupBy(trades, (e: Trade) => new Date(e.closeTimestampUtc.toLocaleString()).toDateString()) as { [date: string]: Trade[] };
    const tradeDays = Object.values(group).map((trades: Trade[]) => {
        const closedDate = trades[0].closeTimestampUtc;
        const profit = trades.map((trade: Trade) => trade.profit).reduce((a, b) => a + b, 0);

        return {
            closedDate: formatDate(closedDate, dateFormat),
            balance: profit.toFixed(2),
        };
    });

    const tradeDaysSorted = tradeDays.sort((a, b) => sortByTimeAsc(a.closedDate, b.closedDate));

    return [...tradeDaysSorted];
}
