var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal-vue",
        attrs: { id: "updateModalWrapper" },
        on: { click: _vm.closePopup }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-content-dynamic",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(
              "span",
              {
                staticClass: "close",
                staticStyle: { float: "right" },
                on: { click: _vm.closePopup }
              },
              [_vm._v("\n                ×\n            ")]
            ),
            _vm._v(" "),
            _c("trade-form-update", {
              attrs: {
                "trade-data": _vm.data,
                "close-popup": _vm.closePopup,
                brokeraccountalias: _vm.broker
              }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "popup has-text info", on: { click: _vm.openPopup } },
      [
        _c("img", {
          staticClass: "icon",
          attrs: { src: "/static/v1.4/img/wrench.svg" }
        }),
        _vm._v("\n        " + _vm._s(_vm.$t("edit_trade")) + "\n    ")
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }