var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("trade-form", {
    attrs: {
      symbols: _vm.symbols,
      loading: _vm.loading,
      errors: _vm.errors,
      "trade-data": _vm.data
    },
    on: {
      send: _vm.handleSend,
      sendDeposit: _vm.handleSend,
      sendWithdrawal: _vm.handleSend
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }