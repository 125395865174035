export function getCurrency(): string {
    const el = document.querySelector("meta#devise");
    return el.getAttribute("data-value");
}

export function getCaptchaSiteKey(): string {
    const el = document.querySelector("meta#captcha-key");
    return el.getAttribute("data-value");
}

export function getCaptchaAction(): string {
    const el = document.querySelector("meta#captcha-action");
    return el.getAttribute("data-value");
}

export function getLocale(): string {
    const el = document.querySelector("meta#lang");
    return el.getAttribute("data-value");
}

export function getStripeKey(): string {
    const el = document.querySelector("meta#stripe-key");
    return el.getAttribute("data-value");
}

export function getSymbol(): string {
    const el = document.querySelector("meta#devise-symbol");
    return el.getAttribute("data-value");
}

interface CsrfInfos {
  token: string;
  header: string;
}

export function getCsrfHeaders(): CsrfInfos {
    const token = document.querySelector("meta[name='_csrf']").getAttribute("content");
    const header = document.querySelector("meta[name='_csrf_header']").getAttribute("content");

    return { token, header };
}
