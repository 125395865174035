<template>
    <div class="row flex-center">
        <div
            v-if="loading"
            id="loading"
            class="loading"
            style="align-items: center; display: flex; justify-content: center"
        >
            <div class="loader">
                <div />
                <div />
            </div>
        </div>

        <div class="col col-6 flex-center">
            <div class="price">
                <div class="item">
                    <form
                        id="payment-form"
                        class="d-block important"
                        style="margin-top: 0px"
                        @submit="pay"
                    >
                        <div class="price_rate pro">
                            <h2>{{ $t('component_order_pro') }}</h2>
                        </div>
                        <h3
                            id="price"
                            style="text-align:center;"
                        >
                            <div :class="[coupon ? 'price--strikethrough' : 'price']">
                                {{ selectedPeriod === "yearly" ? (price / 12).toFixed(2) : price }}
                            </div>
                            <div
                                v-if="coupon"
                                class="price"
                            >
                                {{ selectedPeriod === "yearly" ? reduction(yearPrice / 12) : reduction(monthPrice) }}
                            </div>{{ symbol }}
                            <div
                                v-if="coupon"
                                class="card-price"
                            >
                                -{{ coupon.reduction }}%
                            </div>
                            <span v-if="selectedPeriod === 'yearly'">{{ $t('component_order_per_month_yearly', [reduction(price), symbol]) }}</span>
                            <span v-else>{{ $t('component_order_per_month') }}</span>
                        </h3>

                        <fieldset class="boolean-list spacing">
                            <legend />
                            <input
                                id="yearly"
                                type="radio"
                                name="test"
                                value="yearly"
                                checked="checked"
                                @change="checking"
                            >
                            <label for="yearly">
                                <span
                                    class="promo"
                                    style="color:white;background-color:#0D8065;"
                                >
                                    {{ $t('component_order_most_popular') }}
                                </span>
                                <p class="no-wrap">{{ $t('component_order_billed_annually') }}</p><span class="promo no-wrap">{{ $t('component_order_save') }} 20%</span>
                            </label>
                            <input
                                id="monthly"
                                type="radio"
                                name="test"
                                value="monthly"
                                @change="checking"
                            >
                            <label
                                for="monthly"
                                class="no-wrap"
                            >{{ $t('component_order_billed_monthly') }}</label>
                        </fieldset>

                        <div
                            v-if="stripeError !== ''"
                            id="card-errors"
                            class="alert error"
                            role="alert"
                        >
                            {{ stripeError }}
                        </div>

                        <div
                            id="card-element"
                            class="spacing"
                        >
                            <div class="field">
                                <label class="label_input">{{ $t('component_order_card_number') }}</label>
                                <div
                                    id="cardNumber"
                                    class="inputWrapper centeredContent"
                                />
                            </div>

                            <div class="d-flex">
                                <div class="field flex-grow">
                                    <label class="label_input">{{ $t('component_order_expiration_date') }}</label>
                                    <div
                                        id="cardExpiry"
                                        class="inputWrapper centeredContent"
                                    />
                                </div>

                                <div class="field flex-grow ml-10">
                                    <label class="label_input">{{ $t('component_order_security_code') }}</label>
                                    <div
                                        id="cardCvc"
                                        class="inputWrapper centeredContent"
                                    />
                                </div>
                            </div>
                        </div>
                                  <div class="text-center spacing" style="margin-top:20px;">
                                                                                <input
                                                                                    id="checkout"
                                                                                    name="checkout"
                                                                                    type="submit"
                                                                                    class="btn"
                                                                                    :value="[ $t('checkout') ]"
                                                                                >
                                                                            </div>
                        <ul>
                            <h4>{{ $t('component_order_advanced_tools_to') }}</h4>

                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/trades.png"
                                    alt=""
                                >
                                <div>{{ $t('register_upsell_up_to_302') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/sync.png"
                                    alt=""
                                >
                                <div>{{ $t('track_account1') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/clock.png"
                                    alt=""
                                >
                                <div>{{ $t('hour_update1') }}</div>
                            </li>

                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('goalSet') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('tagSet') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('scoreSet') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('advanceSet') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('monthSet') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('dailySet') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('hourlySet') }}</div>
                            </li>
                            <li>
                                <img
                                    src="https://www.fixytrade.com/wp-content/themes/fixytrade/assets/img/check2.png"
                                    alt=""
                                >
                                <div>{{ $t('holdSet') }}</div>
                            </li>



                        </ul>
                        <h5
                            class="price_aditonal"
                            style="margin:15px 30px;text-align:center;"
                        >
                            {{ $t('component_order_day_satisfaction_guarantee') }} <br>
                            {{ $t('component_order_cancel_anytime') }}
                        </h5>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { swallMessage } from '../../services/alert';
import { getPrices, getStripe, newOfferpay } from '../../services/payment';
import { getLocale, getCurrency, getSymbol } from '../../services/infos';

export default {

    data() {
        let symbol = getSymbol();

        return {
            symbol,
            loading: true,
            coupon: null,

            locale: "en-US",
            currency: null,
            selectedPeriod: 'yearly',

            monthPrice: 0,
            yearPrice: 0,

            stripe: null,

            cardNumber: '',
            cardExpiry: '',
            cardCvc: '',

            stripeError: '',

            card: {
                cvc: '',
                number: '',
                expiry: '',
            },
        };
    },

    computed: {
        price: function() {
            return this.selectedPeriod === "yearly" ? this.yearPrice : this.monthPrice;
        },
    },

    async mounted() {
        this.locale = getLocale();
        this.currency = getCurrency();

        await this.configureStripeForm();
        await this.checkPrices();

        this.loading = false;
    },
    methods: {
        /**
         * Checkbox event for year/month switch
         *
         * @param  {event}
         * @return {null}
         */
        checking(e) {
            this.selectedPeriod = e.target.value;
        },

        /**
         * Initialise stripe form
         *
         * @return {null}
         */
        async configureStripeForm() {
            this.stripe = await getStripe();
            const elements = this.stripe.elements();

            this.cardNumber = elements.create('cardNumber', {});
            this.cardNumber.mount('#cardNumber');

            this.cardExpiry = elements.create('cardExpiry', {});
            this.cardExpiry.mount('#cardExpiry');

            this.cardCvc = elements.create('cardCvc', {});
            this.cardCvc.mount('#cardCvc');

            this.cardNumber.addEventListener('change', (event) => {
                this.toggleError(event);
                this.card.number = event.complete;
            });
            this.cardExpiry.addEventListener('change', (event) => {
                this.toggleError(event);
                this.card.expiry = event.complete;
            });
            this.cardCvc.addEventListener('change', (event) => {
                this.toggleError(event);
                this.card.cvc = event.complete;
            });
        },

        /**
         * display card input error
         *
         * @param  {event}
         * @return {null}
         */
        toggleError: function (event) {
            if (event.error) {
                this.stripeError = event.error.message;
            } else {
                this.stripeError = '';
            }
        },

        /**
         * Fetch prices and check for coupons on the backend
         *
         * @return {null}
         */
        async checkPrices() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const couponId = urlParams.get('coupon');

            const data = await getPrices(couponId, this.currency);

            if (data.coupon !== undefined) {
                this.coupon = data.coupon;
            }

            // for (let price of data.prices) {
            //     if (price.period == "month")
                    this.monthPrice = 17.90;
                // if (price.period == "year")
                    this.yearPrice = 167;
            // }
        },

        /**
         * Display reduction for a price
         *
         * @param  {number}
         * @return {number}
         */
        reduction(price) {
            return this.coupon !== null ? price * (100 - this.coupon.reduction) / 100 : price;
        },

        /**
         * Payment event for the form
         *
         * @param  {event}
         * @return {null}
         */
        async pay(e) {
            e.preventDefault();

            this.loading = true;

            try {
                await newOfferpay(this.stripe, this.cardNumber, this.selectedPeriod, this.locale, this.coupon?.id);

                window.location = "/dashboard?success";
            } catch (e) {
                swallMessage(this.$t(e), "error");
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../config.scss";

$checkout-breakpoint: 900px;

/* UTILS */
.spacing {
    margin-left: 10px;
    margin-right: 10px;
}

@media (min-width: $checkout-breakpoint) {
    .no-wrap {
        white-space: nowrap;
    }
}

/* INLINE PRICES  */
.price {
    display: inline;
}

.price--strikethrough {
    @extend .price;
    opacity: 0.7;
    text-decoration: line-through;
}

.promo{
    background-color: #D6F7EE;
    width: auto;
    padding: 0px 10px 0px 10px;
    border-radius: inherit;
    font-size: 11px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 16px;
    color: #0D8065;

    @media (max-width: $checkout-breakpoint) {
        display: none;
    }
}

$tag-color: rgb(13, 128, 101);
/* PRICE TAG */
.card-price {
    display: inline;
    display: inline-block;

    width: auto;
    height: 38px;

    background-color: $tag-color;
    -webkit-border-radius: 3px 4px 4px 3px;
    -moz-border-radius: 3px 4px 4px 3px;
    border-radius: 3px 4px 4px 3px;

    border-left: 1px solid $tag-color;

    /* This makes room for the triangle */
    margin-left: 19px;

    position: relative;

    color: white;
    font-weight: 300;
    font-size: 22px;
    line-height: 38px;

    padding: 0 10px 0 10px;
}

/* Makes the triangle */
.card-price:before {
    content: "";
    position: absolute;
    display: block;
    left: -19px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 19px solid $tag-color;
}

/* Makes the circle */
.card-price:after {
    content: "";
    background-color: white;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: block;
    position: absolute;
    left: -9px;
    top: 17px;
}
</style>
