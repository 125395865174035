













































import { changePassword } from '../../services/change-password';
import { swallMessage } from '../../services/alert';

export default {
    data() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');

        return {
            formEnabled: true,
            password: "",
            passwordConfirmation: "",
            error: "",
            token,

            sendFormClasses: {
                btn: true,
                'd-block': true,
                'ma-auto': true,
            },
        };
    },

    methods: {
        async askForChange(e: Event) {
            e.preventDefault();
            if (this.formEnabled === false) return false;
            this.formEnabled = false;
            this.error = "";

            try {
                await changePassword(this.token, this.password, this.passwordConfirmation);
                await swallMessage(this.$t('password_has_been_changed'), "info");
                window.location.href = this.$t("url.login");

                this.formEnabled = true;
            } catch (e) {
                this.error = e;
                this.formEnabled = true;
            }
        },
    },
};
